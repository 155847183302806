/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  var lang = $('body').hasClass('lang-fr') ? 'fr' : 'en';

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        UTIL.fire('common', 'fancybox');

    		UTIL.fire('common', 'loadSlider');

    		UTIL.fire('common', 'matchHeight');

        // ContactForm7 DomEvents
        // UTIL.fire('common', '_cf7_events');

        UTIL.fire('common', 'readMore');
      },

      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },

      fancybox: function() {
        $('.fancybox').fancybox();
      },

      // ContactForm7 DomEvents
      // Replace deprecated cf7 function on_sent_ok(), on_submit() or other events
      // https://contactform7.com/dom-events/
      // _cf7_events: function() {
      //   // return;
      //   // wpcf7mailsent: Fires when an Ajax form submission has completed successfully, and mail has been sent.
      //   document.addEventListener( 'wpcf7mailsent', function( event ) {
      //     formConfirmation(event.detail.contactFormId);
      //   }, false );

      //   // wpcf7submit: Fires when an Ajax form submission has completed successfully, regardless of other incidents.
      //   document.addEventListener( 'wpcf7submit', function( event ) {
      //     formScroll(event.detail.contactFormId);
      //   }, false );

      //   // wpcf7invalid: Fires when an Ajax form submission has completed successfully, but mail hasn't been sent because 
      //   // there are fields with invalid input.
      //   // document.addEventListener( 'wpcf7invalid', function( event ) {
      //   //   formInvalidFields(event.detail.contactFormId);
      //   // }, false );
      // },

      loadSlider: function() {
        fpoSliderElem = $("#lightSlider").lightSlider({
  		  item: 4,
  		  loop:true,
        keyPress:true,
  		  controls:false,
  		  prevHtml: '<span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>',
  		  nextHtml: '<span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>',
  		  pager: false,
  		  responsive : [
					{
						breakpoint:800,
						settings: {
  						item:1,
  						controls:false
					  }
					}
   		  ]
        });

    		$('.lSAction .lSPrev').click(function(){
          fpoSliderElem.goToPrevSlide();
          return false;
    	  });

    	  $('.lSAction .lSNext').click(function(){
      		fpoSliderElem.goToNextSlide();
      		return false;
    	  });
      },

      matchHeight: function() {
    		$('.in-products-text').matchHeight();
    		$('.contact-common').matchHeight();
    		$('#siteFooter .footer-block').matchHeight();
      },

      readMore: function(){
        // Collapsed text
        toggleText = function(className){
          var termExpand = (lang == 'fr') ? "Lire la suite" : "Read More";
          var termCollapse = (lang == 'fr') ? "Réduire" : "Collapse Text";

          var symbolExpand = '+';
          var symbolCollapse = '-';

          $(className).each(function(){
            // Add Bootstrap class
            $(this).addClass('collapse');

            if(!$(this).find('+ .readmore').length){
              $(this).after('<p class="readmore"><a href="#"><span>' + symbolExpand + ' </span>'+termExpand+'</a></p>');
            }
          });

          $(className +' + .readmore a').click(function(){
            $trig = $(this);
            $textMore = $(this).parent().prev();

            if($textMore.length){
              $textMore.collapse('toggle');

              // Change trigger text when text is expanded
              $textMore.on('shown.bs.collapse', function(){
                $trig.html('<span>' + symbolCollapse + ' </span>' + termCollapse);
              });
              // Change trigger text when text is collapsed
              $textMore.on('hidden.bs.collapse', function(){
                $trig.html('<span>' + symbolExpand + ' </span>' + termExpand);
              });

              $trig.blur();

              return false;
            }
          });
        };

        // Set all read more texts
        toggleText('.textMore:not(.mobile)');

        // Set all read more texts specific to mobile version
        if($('body.mobile').length) {
          toggleText('.textMore.mobile');
        }
      },
    },

    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
		    UTIL.fire('home', 'finalize');
      },

      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
    		$('#lightSlider .in-slider-head').matchHeight();
    		$('#lightSlider .in-slider-text').matchHeight();
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  // Contact Form 7 custom functions
  pdfDownload = function(file) {
    var msg;
    if ($('body.lang-fr').length) {
      msg = 'Cliquez ici pour télécharger le formulaire complet';
    } else {
      msg = 'Click here to download the complete form';
    }
    setTimeout(function() {
      $('form .wpcf7-response-output').append(' <a href="/wp-content/themes/site/assets/files/'+file+'.pdf" download="'+file+'.pdf">'+msg+'</a>')
    }, 200);
  }
})(jQuery); // Fully reference jQuery after this point.
